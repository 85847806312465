export class Images
{
    public static readonly LogoGrayTransparent = require('./logo_darkblue_transparent.png');
    public static readonly LogoWhiteTransparent = require('./logo_white_transparent.png');
    public static readonly Office1 = require('./office_1.jpg');
    public static readonly Office4 = require('./office_4.jpg');
    public static readonly Office8 = require('./office_8.jpg');
    public static readonly Office10 = require('./office_10.jpg');
    public static readonly Office11 = require('./office_11.jpg');
    public static readonly Office14 = require('./office_14.jpg');
    public static readonly CompanyAddress = require('./company_address.png');
    public static readonly Founder = require('./director.jpg');
    public static readonly HamburgerIcon = require('./hamburger_icon.svg');
}