import * as React from 'react';
import * as classnames from 'classnames/bind';
import {Images} from '../images/Images';
import {MenuItem, TopMenu} from './TopMenu';

const styles = require('./PageHeader.less');
const cx = classnames.bind(styles) as any;

export class PageHeader extends React.Component<any, any>
{
    render()
    {
        return <div className={'container pageHeader'} style={{color:'black'}}>
            <div className={'row'} style={{marginTop:'10px'}}>
                <div className={'col-sm-6'}>
                    <a href={'/'}>
                        <img style={{marginLeft:'0px', cursor:'default'}} width={'300px'} src={Images.LogoGrayTransparent}/>
                    </a>
                </div>
                <div className={'col-sm-6'} style={{display:'flex', justifyContent:'flex-end'}}>
                                     <TopMenu menuItems={
                                         [   new MenuItem('Main','/'),
                                             new MenuItem('Services','/services'),
                                             new MenuItem('Jobs','/careers'),
                                             new MenuItem('Company','/company')
                                         ]}/>

                </div>
            </div>
        </div>;
    }
}